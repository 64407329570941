/* global CookieScript */

import React, { useState, useCallback, useEffect } from 'react';
import Head from 'next/head';
import useMediaQuery from '../../utils/useMediaQuery';
import PropTypes from 'prop-types';
import Headroom from 'react-headroom';
import classNames from 'classnames';

import Navigation from '../../components/Navigation';
import NavigationMobile from '../../components/NavigationMobile';
import Footer from '../../components/Footer';

import BasicLogo from '../../components/BasicLogo';
import NewsletterSignup from '../../components/NewsletterSignup';
import CrisisInformationBar from '../../components/CrisisInformationBar';
import GlobalSiteSwitcher from '../../components/GlobalSiteSwitcher';
import WagtailUserbar from '../../components/WagtailUserbar';
import smoothscroll from 'smoothscroll-polyfill';
import { useTranslation } from 'next-i18next';

import isEmpty from '../../utils/isEmpty';
import { isExternalUrl } from '../../utils/url';

import { breakpoints } from '../../constants';
import { useCookieStateContext, CookieStateContext } from './state';
import styles from './BasePage.module.scss';

export default function BasePage({
    navigation,
    footerNavigation,
    children,
    crisisInformation,
    newsletterSignup,
    breadcrumbs,
    meta,
    siteSetting,
    hideNavigation,
    wagtailUserbar,
    seo,
}) {
    const { t } = useTranslation('common');
    const { siteSwitcher } = siteSetting || {};

    const context = useCookieStateContext();
    const {
        handleCookieAcceptAll,
        handleCookieAcceptCategories,
        handleCookieRejectAll,
    } = context;

    useEffect(() => {
        if (meta && typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                contentgrouping: {
                    area: meta.metaDataArea || '',
                    region: meta.metaDataRegions || '',
                    program: meta.metaDataInternalProgram || '',
                    funding: meta.metaDataFunding || '',
                },
            });
        }
    }, [
        meta?.metaDataArea,
        meta?.metaDataFunding,
        meta?.metaDataInternalProgram,
        meta?.metaDataRegions,
    ]);

    useEffect(() => {
        smoothscroll.polyfill();
    }, []);

    useEffect(() => {
        /*
         * Open external links in new windows
         * this is against accessibility and best practice rules
         * but a requirement for this project
         */
        const links = document.querySelectorAll('a[href]');
        for (let i = 0; i < links.length; i++) {
            const isExternal = isExternalUrl(links[i].getAttribute('href'));

            if (isExternal) {
                links[i].setAttribute('target', '_blank');
                links[i].setAttribute('rel', 'noopener');
            }
        }
    }, []);

    useEffect(() => {
        const handleCookieScriptLoaded = () => {
            handleCookieAcceptCategories(
                CookieScript.instance.currentState().categories
            );
        };

        if (window.CookieScript && window.CookieScript.instance) {
            handleCookieScriptLoaded();
        }

        window.addEventListener('CookieScriptLoaded', handleCookieScriptLoaded);

        window.addEventListener('CookieScriptAccept', (e) => {
            const categories = e.detail?.categories;
            handleCookieAcceptCategories(categories);

            if (
                typeof gtag === 'function' &&
                categories.includes('targeting')
            ) {
                window.dataLayer.push({ event: 'consentGranted' });
            }
        });

        window.addEventListener('CookieScriptAcceptAll', () => {
            handleCookieAcceptAll();
            typeof gtag === 'function' &&
                window.dataLayer.push({ event: 'consentGranted' });
        });

        window.addEventListener('CookieScriptReject', () => {
            handleCookieRejectAll();
        });

        window.addEventListener('CookieScriptLoaded', function (e) {
            console.log('Global: CookieScriptLoaded event detected', e);
        });

        return () => {
            window.removeEventListener(
                'CookieScriptLoaded',
                handleCookieScriptLoaded
            );
            window.removeEventListener(
                'CookieScriptAccept',
                handleCookieAcceptCategories
            );
            window.removeEventListener(
                'CookieScriptAcceptAll',
                handleCookieAcceptAll
            );
            window.removeEventListener(
                'CookieScriptReject',
                handleCookieRejectAll
            );
        };
    }, []);

    const showBreadcrumbs =
        breadcrumbs && breadcrumbs.items && breadcrumbs.items.length > 1;

    const classes = classNames(styles['BasePage'], {
        [styles['BasePage--Breadcrumbs']]: showBreadcrumbs,
    });

    return (
        <>
            <Meta {...seo} />

            <CookieStateContext.Provider value={context}>
                <main className={classes}>
                    <a
                        className={styles['BasePage__SkipMain']}
                        href="#main-content">
                        {t('base.skip')}
                    </a>
                    {!isEmpty(siteSwitcher) && !hideNavigation && (
                        <GlobalSiteSwitcher {...siteSwitcher} />
                    )}
                    {hideNavigation ? (
                        <BasicLogo />
                    ) : (
                        <Header
                            navigation={navigation}
                            crisisInformation={crisisInformation}
                        />
                    )}
                    {children}
                    {!isEmpty(newsletterSignup) && (
                        <NewsletterSignup {...newsletterSignup} />
                    )}
                    <Footer {...footerNavigation} />
                    {wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
                </main>
            </CookieStateContext.Provider>
        </>
    );
}

BasePage.propTypes = {
    children: PropTypes.node,
    navigation: PropTypes.object,
    newsletterSignup: PropTypes.object,
    footerNavigation: PropTypes.object,
    crisisInformation: PropTypes.object,
    breadcrumbs: PropTypes.object,
    meta: PropTypes.object,
    siteSetting: PropTypes.object,
    hideNavigation: PropTypes.bool,
    wagtailUserbar: PropTypes.object,
};

BasePage.defaultProps = {
    hideNavigation: false,
    wagtailUserbar: null,
};

const Header = ({ navigation, crisisInformation }) => {
    const [isHydrated, setIsHydrated] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const handleDisable = useCallback(
        () => setIsDisabled((isDisabled) => !isDisabled),
        []
    );
    const headroomClasses = classNames({ 'Headroom--Sticky': isDisabled });

    useEffect(() => {
        const checkIfDesktop = () => {
            setIsDesktop(window.innerWidth >= breakpoints.l);
        }

        checkIfDesktop();
        window.addEventListener('resize', checkIfDesktop);

        setIsHydrated(true);

        return () => {
            window.removeEventListener('resize', checkIfDesktop);
        }
    }, []);

    if (!isHydrated) {
        return null;
    }

    return (
        <>
            <Headroom className={headroomClasses} disableInlineStyles={true}>
                {isDesktop ? (
                    <Navigation {...navigation} />
                ) : (
                    <NavigationMobile
                        {...navigation}
                        handleDisable={handleDisable}
                    />
                )}
                <CrisisInformationBar {...crisisInformation} />
            </Headroom>
        </>
    );
};

Header.propTypes = {
    navigation: PropTypes.object,
    crisisInformation: PropTypes.object,
};

const Meta = ({
    seoHtmlTitle = '',
    seoMetaDescription = '',
    seoOgUrl = '',
    seoOgDescription = '',
    seoOgTitle = '',
    seoOgImage = null,
    seoOgType = '',
    seoTwitterTitle = '',
    seoTwitterDescription = '',
    seoTwitterUrl = '',
    seoTwitterImage = null,
    seoMetaRobots = '',
    seoCanonicalLink = '',
}) => {
    return (
        <Head>
            <title>{seoHtmlTitle + ' | Visit Sweden'}</title>

            {!!seoMetaDescription && (
                <meta name="description" content={seoMetaDescription} />
            )}

            {!!seoOgUrl && <meta property="og:url" content={seoOgUrl} />}

            {!!seoOgDescription && (
                <meta property="og:description" content={seoOgDescription} />
            )}

            {!!seoOgTitle && <meta property="og:title" content={seoOgTitle} />}

            {!!seoOgImage && <meta property="og:image" content={seoOgImage} />}

            {!!seoOgType && <meta property="og:type" content={seoOgType} />}

            {!!seoTwitterTitle && (
                <meta property="twitter:title" content={seoTwitterTitle} />
            )}

            {!!seoTwitterDescription && (
                <meta
                    property="twitter:description"
                    content={seoTwitterDescription}
                />
            )}

            {!!seoTwitterUrl && (
                <meta property="twitter:url" content={seoTwitterUrl} />
            )}

            {!!seoTwitterImage && (
                <meta property="twitter:image" content={seoTwitterImage} />
            )}

            {!!seoMetaRobots && <meta name="robots" content={seoMetaRobots} />}

            <link rel="canonical" href={seoCanonicalLink} />
        </Head>
    );
};

Meta.propTypes = {
    seoHtmlTitle: PropTypes.string,
    seoMetaDescription: PropTypes.string,
    seoOgUrl: PropTypes.string,
    seoOgDescription: PropTypes.string,
    seoOgTitle: PropTypes.string,
    seoOgImage: PropTypes.string,
    seoOgType: PropTypes.string,
    seoTwitterTitle: PropTypes.string,
    seoTwitterDescription: PropTypes.string,
    seoTwitterUrl: PropTypes.string,
    seoTwitterImage: PropTypes.string,
    seoMetaRobots: PropTypes.string,
    seoCanonicalLink: PropTypes.string,
};
